var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "select_opt_area dg_area",
      attrs: { id: "dg_area_" + _vm.cntrSeq },
    },
    [
      _c(
        "div",
        { staticClass: "select_opt_area mt10" },
        [
          _vm._l(_vm.dgInfo.containerDGList, function (vo, idx) {
            return _c(
              "table",
              { key: "tbl_dg" + idx, staticClass: "tbl_col t2" },
              [
                _vm._m(0, true),
                _c("thead", [
                  _c("tr", [
                    idx === 0
                      ? _c("th", { attrs: { scope: "col" } }, [
                          _vm.canAddDg()
                            ? _c(
                                "button",
                                {
                                  staticClass: "tbl_icon plus",
                                  attrs: { type: "button" },
                                  on: {
                                    click: function ($event) {
                                      return _vm.addContainerDG()
                                    },
                                  },
                                },
                                [_vm._v(" plus ")]
                              )
                            : _vm._e(),
                        ])
                      : _vm._e(),
                    idx !== 0
                      ? _c("td", { attrs: { scope: "col" } })
                      : _vm._e(),
                    _c("th", { attrs: { scope: "col" } }, [
                      _vm._v(_vm._s(_vm.$t("msg.CMBA100.00116"))),
                    ]),
                    _vm._v(" "),
                    _c("th", { attrs: { scope: "col" } }, [
                      _vm._v(_vm._s(_vm.$t("msg.CSBK100.349"))),
                    ]),
                    _c("th", { attrs: { scope: "col" } }, [
                      _vm._v(_vm._s(_vm.$t("msg.CSBK100.351"))),
                    ]),
                    _vm._v(" "),
                    _c("th", { attrs: { scope: "col" } }, [
                      _vm._v(_vm._s(_vm.$t("msg.CSBK100.350"))),
                    ]),
                    _vm._v(" "),
                    _c("th", { attrs: { colspan: "3", scope: "col" } }, [
                      _vm._v(_vm._s(_vm.$t("msg.CSBK100.352"))),
                    ]),
                    _vm._v(" "),
                    _c("th", { attrs: { colspan: "2", scope: "col" } }, [
                      _vm._v(_vm._s(_vm.$t("msg.CSBK100.353"))),
                    ]),
                  ]),
                ]),
                _c("tbody", [
                  _c("tr", [
                    _c("td", { staticClass: "tac", attrs: { rowspan: "11" } }, [
                      _vm.canAddDg()
                        ? _c(
                            "button",
                            {
                              staticClass: "tbl_icon minus",
                              attrs: { type: "button" },
                              on: {
                                click: function ($event) {
                                  return _vm.removeContainerDG(idx)
                                },
                              },
                            },
                            [_vm._v(" minus ")]
                          )
                        : _vm._e(),
                    ]),
                    _c("td", { staticClass: "tac" }, [
                      _vm._v(" " + _vm._s(idx + 1) + " "),
                    ]),
                    _c("td", [
                      _c("div", { staticClass: "search_btn" }, [
                        _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: vo.imdunNo,
                              expression: "vo.imdunNo",
                            },
                          ],
                          staticClass: "inp_responsive_space3",
                          attrs: {
                            id: "imdunNo_" + _vm.cntrSeq + "_" + idx,
                            type: "text",
                            readonly: "",
                          },
                          domProps: { value: vo.imdunNo },
                          on: {
                            input: [
                              function ($event) {
                                if ($event.target.composing) return
                                _vm.$set(vo, "imdunNo", $event.target.value)
                              },
                              function ($event) {
                                return _vm.checkValidationDG("imdunNo", idx)
                              },
                            ],
                            click: function ($event) {
                              $event.preventDefault()
                              return _vm.getUnnoInfo(idx)
                            },
                          },
                        }),
                        _c("span", [
                          _c("button", {
                            staticClass: "search_btn01",
                            on: {
                              click: function ($event) {
                                $event.preventDefault()
                                return _vm.getUnnoInfo(idx)
                              },
                            },
                          }),
                        ]),
                      ]),
                    ]),
                    _c("td", [
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: vo.imoClasCd,
                            expression: "vo.imoClasCd",
                          },
                        ],
                        attrs: {
                          id: "imoClasCd_" + _vm.cntrSeq + "_" + idx,
                          type: "text",
                          readonly: "",
                        },
                        domProps: { value: vo.imoClasCd },
                        on: {
                          input: function ($event) {
                            if ($event.target.composing) return
                            _vm.$set(vo, "imoClasCd", $event.target.value)
                          },
                        },
                      }),
                    ]),
                    _c("td", [
                      _c(
                        "select",
                        {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: vo.dgPkgGrpCd,
                              expression: "vo.dgPkgGrpCd",
                            },
                          ],
                          attrs: {
                            id: "dgPkgGrpCd_" + _vm.cntrSeq + "_" + idx,
                            disabled: "",
                          },
                          on: {
                            change: [
                              function ($event) {
                                var $$selectedVal = Array.prototype.filter
                                  .call($event.target.options, function (o) {
                                    return o.selected
                                  })
                                  .map(function (o) {
                                    var val = "_value" in o ? o._value : o.value
                                    return val
                                  })
                                _vm.$set(
                                  vo,
                                  "dgPkgGrpCd",
                                  $event.target.multiple
                                    ? $$selectedVal
                                    : $$selectedVal[0]
                                )
                              },
                              function ($event) {
                                return _vm.checkValidationDG("dgPkgGrpCd", idx)
                              },
                            ],
                          },
                        },
                        [
                          _c("option", { attrs: { value: " " } }, [
                            _vm._v("-----"),
                          ]),
                          _c("option", { attrs: { value: "01" } }, [
                            _vm._v("III"),
                          ]),
                          _c("option", { attrs: { value: "02" } }, [
                            _vm._v("II"),
                          ]),
                          _c("option", { attrs: { value: "03" } }, [
                            _vm._v("I"),
                          ]),
                          _c("option", { attrs: { value: "04" } }, [
                            _vm._v("-"),
                          ]),
                        ]
                      ),
                    ]),
                    _c("td", { attrs: { colspan: "3" } }, [
                      _vm._v(" " + _vm._s(vo.dsc) + " "),
                    ]),
                    _c("td", { attrs: { colspan: "2" } }, [
                      _c(
                        "select",
                        {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: vo.imdgGrpCd,
                              expression: "vo.imdgGrpCd",
                            },
                          ],
                          attrs: {
                            id: "imdgGrpCd_" + _vm.cntrSeq + "_" + idx,
                            disabled초: "",
                          },
                          on: {
                            change: [
                              function ($event) {
                                var $$selectedVal = Array.prototype.filter
                                  .call($event.target.options, function (o) {
                                    return o.selected
                                  })
                                  .map(function (o) {
                                    var val = "_value" in o ? o._value : o.value
                                    return val
                                  })
                                _vm.$set(
                                  vo,
                                  "imdgGrpCd",
                                  $event.target.multiple
                                    ? $$selectedVal
                                    : $$selectedVal[0]
                                )
                              },
                              function ($event) {
                                return _vm.checkValidationDG("imdgGrpCd", idx)
                              },
                            ],
                          },
                        },
                        _vm._l(_vm.commonCodes["VS044"], function (cvo) {
                          return _c(
                            "option",
                            { key: cvo.cd, domProps: { value: cvo.cd } },
                            [_vm._v(_vm._s(cvo.cdNm))]
                          )
                        }),
                        0
                      ),
                    ]),
                  ]),
                  _c("tr", [
                    _c(
                      "th",
                      { staticClass: "bor-left", attrs: { scope: "col" } },
                      [
                        _vm._v(_vm._s(_vm.$t("msg.CSBK100.354"))),
                        _c("br"),
                        _vm._v(_vm._s(_vm.$t("msg.CSBK100.355"))),
                      ]
                    ),
                    _c("th", { attrs: { scope: "col" } }, [
                      _vm._v(_vm._s(_vm.$t("msg.CSBK100.356"))),
                    ]),
                    _vm._v(" "),
                    _c("th", { attrs: { scope: "col" } }, [
                      _vm._v(_vm._s(_vm.$t("msg.CSBK100.387"))),
                    ]),
                    _vm._v(" "),
                    _c("th", { attrs: { scope: "col" } }, [
                      _vm._v(_vm._s(_vm.$t("msg.CSBK100.358"))),
                    ]),
                    _vm._v(" "),
                    _c("th", { attrs: { scope: "col" } }, [
                      _vm._v(_vm._s(_vm.$t("msg.CSBK100.359"))),
                    ]),
                    _vm._v(" "),
                    _c("th", { attrs: { scope: "col" } }, [
                      _vm._v(_vm._s(_vm.$t("msg.CSBK100.361"))),
                    ]),
                    _vm._v(" "),
                    _c("th", { attrs: { scope: "col" } }, [
                      _vm._v(_vm._s(_vm.$t("msg.CSBK100.361"))),
                    ]),
                    _vm._v(" "),
                    _c("th", { attrs: { colspan: "2", scope: "col" } }, [
                      _vm._v(_vm._s(_vm.$t("msg.CSBK100.362"))),
                    ]),
                  ]),
                  _c("tr", [
                    _c("td", { staticClass: "bor-left" }, [
                      _c(
                        "select",
                        {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: vo.dgRsdCd,
                              expression: "vo.dgRsdCd",
                            },
                          ],
                          attrs: { id: "dgRsdCd_" + _vm.cntrSeq + "_" + idx },
                          on: {
                            change: [
                              function ($event) {
                                var $$selectedVal = Array.prototype.filter
                                  .call($event.target.options, function (o) {
                                    return o.selected
                                  })
                                  .map(function (o) {
                                    var val = "_value" in o ? o._value : o.value
                                    return val
                                  })
                                _vm.$set(
                                  vo,
                                  "dgRsdCd",
                                  $event.target.multiple
                                    ? $$selectedVal
                                    : $$selectedVal[0]
                                )
                              },
                              function ($event) {
                                return _vm.checkValidationDG("dgRsdCd", idx)
                              },
                            ],
                          },
                        },
                        [
                          _c("option", { attrs: { value: "" } }, [
                            _vm._v("----------"),
                          ]),
                          _c("option", { attrs: { value: "01" } }, [
                            _vm._v("Full"),
                          ]),
                          _c("option", { attrs: { value: "02" } }, [
                            _vm._v("Residue"),
                          ]),
                        ]
                      ),
                    ]),
                    _c(
                      "td",
                      { staticClass: "tal" },
                      [
                        _c("e-input-number", {
                          attrs: {
                            id: "flsTmpr_" + _vm.cntrSeq + "_" + idx,
                            "is-minus": true,
                            "input-class": "col_5",
                            value: vo.flsTmpr,
                            point: 2,
                            maxlength: "5",
                          },
                          on: {
                            input: function ($event) {
                              return _vm.checkValidationDG("flsTmpr", idx)
                            },
                          },
                          model: {
                            value: vo.flsTmpr,
                            callback: function ($$v) {
                              _vm.$set(vo, "flsTmpr", $$v)
                            },
                            expression: "vo.flsTmpr",
                          },
                        }),
                        _c(
                          "select",
                          {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value: vo.tmprUnitCd,
                                expression: "vo.tmprUnitCd",
                              },
                            ],
                            staticClass: "col_4",
                            attrs: {
                              id: "tmprUnitCd_" + _vm.cntrSeq + "_" + idx,
                            },
                            on: {
                              change: function ($event) {
                                var $$selectedVal = Array.prototype.filter
                                  .call($event.target.options, function (o) {
                                    return o.selected
                                  })
                                  .map(function (o) {
                                    var val = "_value" in o ? o._value : o.value
                                    return val
                                  })
                                _vm.$set(
                                  vo,
                                  "tmprUnitCd",
                                  $event.target.multiple
                                    ? $$selectedVal
                                    : $$selectedVal[0]
                                )
                              },
                            },
                          },
                          [
                            _c("option", { attrs: { value: "C" } }, [
                              _vm._v("℃"),
                            ]),
                            _c("option", { attrs: { value: "F" } }, [
                              _vm._v("℉"),
                            ]),
                          ]
                        ),
                      ],
                      1
                    ),
                    _c(
                      "td",
                      { staticClass: "tal" },
                      [
                        _c("e-input-number", {
                          attrs: {
                            id: "grsWt_" + _vm.cntrSeq + "_" + idx,
                            "is-comma": true,
                            point: 3,
                            "input-class": "col_6",
                            value: vo.grsWt,
                            readonly: _vm.parentInfo.cntrTypCd == "TK",
                            maxlength: 16,
                          },
                          on: {
                            input: function ($event) {
                              return _vm.checkValidationDG("grsWt", idx)
                            },
                          },
                          model: {
                            value: vo.grsWt,
                            callback: function ($$v) {
                              _vm.$set(vo, "grsWt", $$v)
                            },
                            expression: "vo.grsWt",
                          },
                        }),
                        _vm._v(" kg "),
                      ],
                      1
                    ),
                    _c(
                      "td",
                      { staticClass: "tal" },
                      [
                        _c("e-input-number", {
                          attrs: {
                            id: "netWt_" + _vm.cntrSeq + "_" + idx,
                            "is-comma": true,
                            point: 3,
                            "input-class": "col_7",
                            value: vo.netWt,
                            maxlength: 16,
                          },
                          on: {
                            input: function ($event) {
                              return _vm.checkValidationDG("netWt", idx)
                            },
                          },
                          model: {
                            value: vo.netWt,
                            callback: function ($$v) {
                              _vm.$set(vo, "netWt", $$v)
                            },
                            expression: "vo.netWt",
                          },
                        }),
                        _vm._v(" kg "),
                      ],
                      1
                    ),
                    _c("td", [
                      _c(
                        "select",
                        {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: vo.mpltCd,
                              expression: "vo.mpltCd",
                            },
                          ],
                          attrs: {
                            id: "mpltCd_" + _vm.cntrSeq + "_" + idx,
                            disabled: "",
                          },
                          on: {
                            change: [
                              function ($event) {
                                var $$selectedVal = Array.prototype.filter
                                  .call($event.target.options, function (o) {
                                    return o.selected
                                  })
                                  .map(function (o) {
                                    var val = "_value" in o ? o._value : o.value
                                    return val
                                  })
                                _vm.$set(
                                  vo,
                                  "mpltCd",
                                  $event.target.multiple
                                    ? $$selectedVal
                                    : $$selectedVal[0]
                                )
                              },
                              function ($event) {
                                _vm.checkValidationDG("mpltCd", idx)
                                _vm.checkValidationDG("tchlNm", idx)
                              },
                            ],
                          },
                        },
                        [
                          _c("option", { attrs: { value: "" } }, [
                            _vm._v(_vm._s(_vm.$t("msg.CSBK100.062"))),
                          ]),
                          _c("option", { attrs: { value: "Y" } }, [
                            _vm._v("Y"),
                          ]),
                          _c("option", { attrs: { value: "N" } }, [
                            _vm._v("N"),
                          ]),
                        ]
                      ),
                    ]),
                    _c("td", [
                      _c(
                        "select",
                        {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: vo.emsFeCd,
                              expression: "vo.emsFeCd",
                            },
                          ],
                          attrs: { id: "emsFeCd_" + _vm.cntrSeq + "_" + idx },
                          on: {
                            change: function ($event) {
                              var $$selectedVal = Array.prototype.filter
                                .call($event.target.options, function (o) {
                                  return o.selected
                                })
                                .map(function (o) {
                                  var val = "_value" in o ? o._value : o.value
                                  return val
                                })
                              _vm.$set(
                                vo,
                                "emsFeCd",
                                $event.target.multiple
                                  ? $$selectedVal
                                  : $$selectedVal[0]
                              )
                            },
                          },
                        },
                        _vm._l(_vm.emsFeCdList, function (cvo) {
                          return _c(
                            "option",
                            { key: cvo, domProps: { value: cvo } },
                            [_vm._v(" " + _vm._s(cvo) + " ")]
                          )
                        }),
                        0
                      ),
                    ]),
                    _c("td", [
                      _c(
                        "select",
                        {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: vo.emsSeCd,
                              expression: "vo.emsSeCd",
                            },
                          ],
                          attrs: { id: "emsSeCd_" + _vm.cntrSeq + "_" + idx },
                          on: {
                            change: function ($event) {
                              var $$selectedVal = Array.prototype.filter
                                .call($event.target.options, function (o) {
                                  return o.selected
                                })
                                .map(function (o) {
                                  var val = "_value" in o ? o._value : o.value
                                  return val
                                })
                              _vm.$set(
                                vo,
                                "emsSeCd",
                                $event.target.multiple
                                  ? $$selectedVal
                                  : $$selectedVal[0]
                              )
                            },
                          },
                        },
                        _vm._l(_vm.emsSeCdList, function (cvo) {
                          return _c(
                            "option",
                            { key: cvo, domProps: { value: cvo } },
                            [_vm._v(" " + _vm._s(cvo) + " ")]
                          )
                        }),
                        0
                      ),
                    ]),
                    _c(
                      "td",
                      [
                        _c("e-input-number", {
                          attrs: {
                            "is-comma": true,
                            id: "dgPkgQty_" + _vm.cntrSeq + "_" + idx,
                            "input-class": "dgpkg",
                            value: vo.dgPkgQty,
                            maxlength: 12,
                            readonly: _vm.parentInfo.cntrTypCd == "TK",
                          },
                          on: {
                            input: function ($event) {
                              return _vm.checkValidationDG("dgPkgQty", idx)
                            },
                          },
                          model: {
                            value: vo.dgPkgQty,
                            callback: function ($$v) {
                              _vm.$set(vo, "dgPkgQty", $$v)
                            },
                            expression: "vo.dgPkgQty",
                          },
                        }),
                      ],
                      1
                    ),
                    _c("td", [
                      _c(
                        "select",
                        {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: vo.dgMatlCd,
                              expression: "vo.dgMatlCd",
                            },
                          ],
                          attrs: { id: "dgMatlCd_" + _vm.cntrSeq + "_" + idx },
                          on: {
                            change: [
                              function ($event) {
                                var $$selectedVal = Array.prototype.filter
                                  .call($event.target.options, function (o) {
                                    return o.selected
                                  })
                                  .map(function (o) {
                                    var val = "_value" in o ? o._value : o.value
                                    return val
                                  })
                                _vm.$set(
                                  vo,
                                  "dgMatlCd",
                                  $event.target.multiple
                                    ? $$selectedVal
                                    : $$selectedVal[0]
                                )
                              },
                              function ($event) {
                                return _vm.changeDgOuter(vo, idx)
                              },
                            ],
                          },
                        },
                        [
                          _c("option", { attrs: { value: "" } }, [
                            _vm._v("-------------------------------"),
                          ]),
                          _vm._l(vo.outerPkgList, function (cvo) {
                            return _c(
                              "option",
                              { key: cvo.cd, domProps: { value: cvo.cd } },
                              [_vm._v(_vm._s(cvo.cdNm))]
                            )
                          }),
                        ],
                        2
                      ),
                    ]),
                  ]),
                  _c("tr", [
                    _c(
                      "th",
                      {
                        staticClass: "bor-left",
                        attrs: { colspan: "4", scope: "col" },
                      },
                      [_vm._v(_vm._s(_vm.$t("msg.CSBK100.363")))]
                    ),
                    _vm._v(" "),
                    _c("th", { attrs: { scope: "col" } }, [
                      _vm._v(_vm._s(_vm.$t("msg.CSBK100.364"))),
                    ]),
                    _vm._v(" "),
                    _c("th", { attrs: { colspan: "2", scope: "col" } }, [
                      _vm._v(_vm._s(_vm.$t("msg.CSBK100.365"))),
                    ]),
                    _vm._v(" "),
                    _c("th", { attrs: { colspan: "2", scope: "col" } }, [
                      _vm._v(_vm._s(_vm.$t("msg.CSBK100.366"))),
                    ]),
                  ]),
                  _c("tr", [
                    _c(
                      "td",
                      { staticClass: "bor-left tac", attrs: { colspan: "4" } },
                      [
                        _c("input", {
                          directives: [
                            {
                              name: "show",
                              rawName: "v-show",
                              value:
                                vo.mpltCd === "Y" || vo.tchlNmMdtyYn === "Y",
                              expression:
                                "vo.mpltCd === 'Y' || vo.tchlNmMdtyYn === 'Y'",
                            },
                            {
                              name: "model",
                              rawName: "v-model",
                              value: vo.tchlNm,
                              expression: "vo.tchlNm",
                            },
                          ],
                          attrs: {
                            id: "tchlNm_" + _vm.cntrSeq + "_" + idx,
                            type: "text",
                            disabled: "",
                          },
                          domProps: { value: vo.tchlNm },
                          on: {
                            change: function ($event) {
                              return _vm.handleChange()
                            },
                            input: function ($event) {
                              if ($event.target.composing) return
                              _vm.$set(vo, "tchlNm", $event.target.value)
                            },
                          },
                        }),
                        _c(
                          "span",
                          {
                            directives: [
                              {
                                name: "show",
                                rawName: "v-show",
                                value:
                                  vo.mpltCd !== "Y" && vo.tchlNmMdtyYn !== "Y",
                                expression:
                                  "vo.mpltCd !== 'Y' && vo.tchlNmMdtyYn !== 'Y'",
                              },
                            ],
                          },
                          [_vm._v("-")]
                        ),
                      ]
                    ),
                    _c("td", { staticClass: "tac" }, [
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: vo.lqYn,
                            expression: "vo.lqYn",
                          },
                        ],
                        attrs: {
                          id: "lqYn_" + _vm.cntrSeq + "_" + idx,
                          type: "checkbox",
                          "true-value": "Y",
                          "false-value": "N",
                        },
                        domProps: {
                          checked: vo.lqYn === "Y",
                          checked: Array.isArray(vo.lqYn)
                            ? _vm._i(vo.lqYn, null) > -1
                            : _vm._q(vo.lqYn, "Y"),
                        },
                        on: {
                          change: function ($event) {
                            var $$a = vo.lqYn,
                              $$el = $event.target,
                              $$c = $$el.checked ? "Y" : "N"
                            if (Array.isArray($$a)) {
                              var $$v = null,
                                $$i = _vm._i($$a, $$v)
                              if ($$el.checked) {
                                $$i < 0 &&
                                  _vm.$set(vo, "lqYn", $$a.concat([$$v]))
                              } else {
                                $$i > -1 &&
                                  _vm.$set(
                                    vo,
                                    "lqYn",
                                    $$a.slice(0, $$i).concat($$a.slice($$i + 1))
                                  )
                              }
                            } else {
                              _vm.$set(vo, "lqYn", $$c)
                            }
                          },
                        },
                      }),
                      _c(
                        "label",
                        { attrs: { for: "lqYn_" + _vm.cntrSeq + "_" + idx } },
                        [
                          _c("span", { staticClass: "offscreen" }, [
                            _vm._v("L/Q YN"),
                          ]),
                        ]
                      ),
                    ]),
                    _c(
                      "td",
                      [
                        _c("e-input-number", {
                          attrs: {
                            id: "saptTmpr_" + _vm.cntrSeq + "_" + idx,
                            "is-comma": true,
                            "input-class": "saptTmpr",
                            value: vo.saptTmpr,
                          },
                          model: {
                            value: vo.saptTmpr,
                            callback: function ($$v) {
                              _vm.$set(vo, "saptTmpr", $$v)
                            },
                            expression: "vo.saptTmpr",
                          },
                        }),
                      ],
                      1
                    ),
                    _c("td", [
                      _c(
                        "select",
                        {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: vo.saptTmprUnitCd,
                              expression: "vo.saptTmprUnitCd",
                            },
                          ],
                          attrs: {
                            id: "saptTmprUnitCd_" + _vm.cntrSeq + "_" + idx,
                            readonly: "",
                          },
                          on: {
                            change: function ($event) {
                              var $$selectedVal = Array.prototype.filter
                                .call($event.target.options, function (o) {
                                  return o.selected
                                })
                                .map(function (o) {
                                  var val = "_value" in o ? o._value : o.value
                                  return val
                                })
                              _vm.$set(
                                vo,
                                "saptTmprUnitCd",
                                $event.target.multiple
                                  ? $$selectedVal
                                  : $$selectedVal[0]
                              )
                            },
                          },
                        },
                        [
                          _c("option", { attrs: { value: "C" } }, [
                            _vm._v("℃"),
                          ]),
                          _c("option", { attrs: { value: "F" } }, [
                            _vm._v("℉"),
                          ]),
                        ]
                      ),
                    ]),
                    _c(
                      "td",
                      [
                        _c("e-input-number", {
                          attrs: {
                            "is-comma": true,
                            id: "isdDgPkgQty_" + _vm.cntrSeq + "_" + idx,
                            "input-class": "isdDgpkg",
                            value: vo.isdDgPkgQty,
                          },
                          on: {
                            input: function ($event) {
                              return _vm.checkValidationDG("isdDgPkgQty", idx)
                            },
                          },
                          model: {
                            value: vo.isdDgPkgQty,
                            callback: function ($$v) {
                              _vm.$set(vo, "isdDgPkgQty", $$v)
                            },
                            expression: "vo.isdDgPkgQty",
                          },
                        }),
                      ],
                      1
                    ),
                    _c("td", [
                      _c(
                        "select",
                        {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: vo.dgCatCd,
                              expression: "vo.dgCatCd",
                            },
                          ],
                          attrs: { id: "dgCatCd_" + _vm.cntrSeq + "_" + idx },
                          on: {
                            change: [
                              function ($event) {
                                var $$selectedVal = Array.prototype.filter
                                  .call($event.target.options, function (o) {
                                    return o.selected
                                  })
                                  .map(function (o) {
                                    var val = "_value" in o ? o._value : o.value
                                    return val
                                  })
                                _vm.$set(
                                  vo,
                                  "dgCatCd",
                                  $event.target.multiple
                                    ? $$selectedVal
                                    : $$selectedVal[0]
                                )
                              },
                              function ($event) {
                                return _vm.checkValidationDG("dgCatCd", idx)
                              },
                            ],
                          },
                        },
                        _vm._l(vo.innerPkgList, function (cvo) {
                          return _c(
                            "option",
                            { key: cvo.cd, domProps: { value: cvo.cd } },
                            [_vm._v(_vm._s(cvo.cdNm))]
                          )
                        }),
                        0
                      ),
                    ]),
                  ]),
                  _c("tr", [
                    _c(
                      "th",
                      {
                        staticClass: "bor-left",
                        attrs: { scope: "col", colspan: "2" },
                      },
                      [_vm._v(_vm._s(_vm.$t("msg.CSBK100.367")))]
                    ),
                    _vm._v(" "),
                    _c("th", { attrs: { scope: "col" } }, [
                      _vm._v(_vm._s(_vm.$t("msg.CSBK100.368"))),
                    ]),
                    _vm._v(" "),
                    _c("th", { attrs: { scope: "col" } }, [
                      _vm._v(_vm._s(_vm.$t("msg.CSBK100.369"))),
                    ]),
                    _vm._v(" "),
                    _c("th", {
                      staticClass: "tal",
                      attrs: { scope: "col", colspan: "5" },
                    }),
                  ]),
                  _c("tr", [
                    _c(
                      "td",
                      { staticClass: "bor-left", attrs: { colspan: "2" } },
                      [
                        _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: vo.emrPicNm,
                              expression: "vo.emrPicNm",
                            },
                          ],
                          attrs: {
                            id: "emrPicNm_" + _vm.cntrSeq + "_" + idx,
                            type: "text",
                          },
                          domProps: { value: vo.emrPicNm },
                          on: {
                            keyup: function ($event) {
                              return _vm.checkValidationDG("emrPicNm", idx)
                            },
                            input: function ($event) {
                              if ($event.target.composing) return
                              _vm.$set(vo, "emrPicNm", $event.target.value)
                            },
                          },
                        }),
                      ]
                    ),
                    _c(
                      "td",
                      [
                        _c("e-input-number", {
                          attrs: {
                            id: "emrTelCtrNo_" + _vm.cntrSeq + "_" + idx,
                            type: "text",
                            maxlength: "4",
                          },
                          on: {
                            keyup: function ($event) {
                              return _vm.checkValidationDG("emrTelCtrNo", idx)
                            },
                          },
                          model: {
                            value: vo.emrTelCtrNo,
                            callback: function ($$v) {
                              _vm.$set(vo, "emrTelCtrNo", $$v)
                            },
                            expression: "vo.emrTelCtrNo",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "td",
                      [
                        _c("e-input-number", {
                          attrs: {
                            id: "emrTelNo_" + _vm.cntrSeq + "_" + idx,
                            value: vo.emrTelNo,
                            "is-phone": true,
                            maxlength: 26,
                          },
                          on: {
                            input: function ($event) {
                              return _vm.checkValidationDG("emrTelNo", idx)
                            },
                          },
                          model: {
                            value: vo.emrTelNo,
                            callback: function ($$v) {
                              _vm.$set(vo, "emrTelNo", $$v)
                            },
                            expression: "vo.emrTelNo",
                          },
                        }),
                      ],
                      1
                    ),
                    _c("td", {
                      staticClass: "bor-left bor-r0",
                      attrs: { colspan: "5" },
                    }),
                  ]),
                  _c("tr", [
                    _c(
                      "th",
                      {
                        staticClass: "bor-left",
                        attrs: { scope: "col", colspan: "4" },
                      },
                      [_vm._v("Additional Information")]
                    ),
                    _c(
                      "th",
                      {
                        staticClass: "tal",
                        attrs: { scope: "col", colspan: "5" },
                      },
                      [
                        vo.addInfoMsg !== undefined && vo.addInfoMsg !== ""
                          ? _c("span", [_vm._v("Q. " + _vm._s(vo.addInfoMsg))])
                          : _vm._e(),
                      ]
                    ),
                  ]),
                  _c("tr", [
                    _c(
                      "td",
                      {
                        staticClass: "bor-left bor-r0",
                        attrs: { colspan: "9" },
                      },
                      [
                        vo.addInfoMsgList !== undefined &&
                        vo.addInfoMsgList !== null &&
                        vo.addInfoMsgList.length > 0
                          ? _c(
                              "select",
                              {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model",
                                    value: vo.repsCont,
                                    expression: "vo.repsCont",
                                  },
                                ],
                                attrs: {
                                  id: "repsCont_" + _vm.cntrSeq + "_" + idx,
                                },
                                on: {
                                  change: [
                                    function ($event) {
                                      var $$selectedVal = Array.prototype.filter
                                        .call(
                                          $event.target.options,
                                          function (o) {
                                            return o.selected
                                          }
                                        )
                                        .map(function (o) {
                                          var val =
                                            "_value" in o ? o._value : o.value
                                          return val
                                        })
                                      _vm.$set(
                                        vo,
                                        "repsCont",
                                        $event.target.multiple
                                          ? $$selectedVal
                                          : $$selectedVal[0]
                                      )
                                    },
                                    function ($event) {
                                      return _vm.checkValidationDG(
                                        "repsCont",
                                        idx
                                      )
                                    },
                                  ],
                                },
                              },
                              [
                                _c("option", { attrs: { value: "" } }, [
                                  _vm._v("=== Select the answer ==="),
                                ]),
                                _vm._l(vo.addInfoMsgList, function (cvo, i) {
                                  return _c(
                                    "option",
                                    {
                                      key: i,
                                      domProps: { value: cvo.rspsCont },
                                    },
                                    [_vm._v(_vm._s(cvo.rspsCont))]
                                  )
                                }),
                              ],
                              2
                            )
                          : _vm._e(),
                      ]
                    ),
                  ]),
                  _vm._m(1, true),
                  _c("tr", [
                    _c(
                      "td",
                      {
                        staticClass: "bor-left bor-r0",
                        attrs: { colspan: "4" },
                      },
                      [
                        _c(
                          "div",
                          _vm._l(vo.casNoList, function (cvo, i) {
                            return _c(
                              "div",
                              {
                                key: i + _vm.forceUpdate,
                                staticClass: "tbl_form cas_area",
                              },
                              [
                                _c("span", { staticClass: "dv wid20" }, [
                                  i === 0
                                    ? _c(
                                        "button",
                                        {
                                          staticClass: "tbl_icon plus",
                                          attrs: { type: "button" },
                                          on: {
                                            click: function ($event) {
                                              return _vm.addCasNo(idx)
                                            },
                                          },
                                        },
                                        [_vm._v(" plus ")]
                                      )
                                    : _vm._e(),
                                  i !== 0
                                    ? _c(
                                        "button",
                                        {
                                          staticClass: "tbl_icon minus",
                                          attrs: { type: "button" },
                                          on: {
                                            click: function ($event) {
                                              return _vm.removeCasNo(idx, i)
                                            },
                                          },
                                        },
                                        [_vm._v(" minus ")]
                                      )
                                    : _vm._e(),
                                ]),
                                _c(
                                  "span",
                                  { staticClass: "dv position_relative" },
                                  [
                                    _c("e-input-number", {
                                      attrs: {
                                        id: "casNo_" + idx + "_" + i,
                                        maxlength: "10",
                                      },
                                      on: {
                                        input: function ($event) {
                                          return _vm.checkValidationCasNo(
                                            cvo,
                                            idx,
                                            i
                                          )
                                        },
                                      },
                                      model: {
                                        value: cvo.casNo,
                                        callback: function ($$v) {
                                          _vm.$set(cvo, "casNo", $$v)
                                        },
                                        expression: "cvo.casNo",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                                _c("span", { staticClass: "dv" }),
                              ]
                            )
                          }),
                          0
                        ),
                      ]
                    ),
                    _c(
                      "td",
                      {
                        staticClass: "bor-left bor-r0",
                        attrs: { colspan: "3" },
                      },
                      [
                        _vm._l(vo.casNoList, function (cvo, i) {
                          return _c(
                            "div",
                            { key: i, staticClass: "tbl_form cas_area" },
                            [
                              _c("e-input-number", {
                                attrs: {
                                  id: "rto_" + idx + "_" + i,
                                  point: 2,
                                  "digit-max": 3,
                                  maxlength: "6",
                                },
                                model: {
                                  value: cvo.rto,
                                  callback: function ($$v) {
                                    _vm.$set(cvo, "rto", $$v)
                                  },
                                  expression: "cvo.rto",
                                },
                              }),
                            ],
                            1
                          )
                        }),
                      ],
                      2
                    ),
                    _c("td", { attrs: { colspan: "2" } }),
                  ]),
                ]),
              ]
            )
          }),
          _c("table", { staticClass: "tbl_col t2 mt10" }, [
            _vm._m(2),
            _c("tbody", [
              _vm.lang === "ENG" && _vm.vanningDtYn === "Y"
                ? _c("tr", [
                    _c("th", { attrs: { scope: "row" } }, [
                      _vm._v("Vanning Date"),
                    ]),
                    _c("td", { attrs: { colspan: "3" } }, [
                      _c(
                        "div",
                        { staticClass: "input_calendar" },
                        [
                          _c("e-date-picker", {
                            attrs: {
                              id: "vaniDt_" + _vm.cntrSeq,
                              type: "text",
                              placeholder: "YYYY-MM-DD",
                            },
                            model: {
                              value: _vm.dgInfo.vaniDt,
                              callback: function ($$v) {
                                _vm.$set(_vm.dgInfo, "vaniDt", $$v)
                              },
                              expression: "dgInfo.vaniDt",
                            },
                          }),
                        ],
                        1
                      ),
                    ]),
                  ])
                : _vm._e(),
              _c("tr", [
                _c("th", { attrs: { scope: "row" } }, [_vm._v("File Upload")]),
                _c("td", { attrs: { colspan: "3" } }, [
                  _c(
                    "div",
                    {
                      staticClass: "filebox",
                      attrs: { id: "srDgFileBox_" + _vm.parentInfo.cntrSeq },
                    },
                    [
                      _c(
                        "a",
                        {
                          staticClass: "button sm",
                          class: _vm.isFileUpload() ? "blue" : "gray file",
                          on: {
                            click: function ($event) {
                              return _vm.openUploadPopup()
                            },
                          },
                        },
                        [_vm._v(" " + _vm._s(_vm.$t("msg.CSBK100.205")) + " ")]
                      ),
                    ]
                  ),
                ]),
              ]),
              _c("tr", [
                _c("th", { attrs: { scope: "row" } }, [
                  _vm._v("Request Remark"),
                ]),
                _c("td", { attrs: { colspan: "3" } }, [
                  _c("textarea", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.dgInfo.reqConts,
                        expression: "dgInfo.reqConts",
                      },
                    ],
                    domProps: { value: _vm.dgInfo.reqConts },
                    on: {
                      change: _vm.handleChange,
                      input: function ($event) {
                        if ($event.target.composing) return
                        _vm.$set(_vm.dgInfo, "reqConts", $event.target.value)
                      },
                    },
                  }),
                ]),
              ]),
              _c("tr", [
                _c("th", { attrs: { scope: "row" } }, [_vm._v("Status")]),
                _c("td", [
                  _c("span", { staticClass: "span_scg" }, [
                    _vm._v(_vm._s(_vm.dgInfo.apvStsNm)),
                  ]),
                ]),
                _c("th", { attrs: { scope: "row" } }, [_vm._v("Ref No.")]),
                _c("td", [
                  _c("span", { staticClass: "span_scg" }, [
                    _vm._v(_vm._s(_vm.dgInfo.scgAppNo)),
                  ]),
                ]),
              ]),
              _c("tr", [
                _c("th", { attrs: { scope: "row" } }, [_vm._v("KMTC Remark")]),
                _c("td", { attrs: { colspan: "3" } }, [
                  _c("textarea", {
                    staticClass: "txt_border_none",
                    attrs: { readonly: "" },
                    domProps: { value: _vm.dgInfo.apvCont },
                  }),
                ]),
              ]),
            ]),
          ]),
        ],
        2
      ),
      _c(
        "win-layer-pop",
        { staticClass: "popup_dim_normal" },
        [
          _vm.customComponent
            ? _c(_vm.customComponent, {
                tag: "component",
                attrs: { "parent-info": _vm.popParams },
                on: { close: _vm.closePoup, selectFunc: _vm.setUnnoInfo },
              })
            : _vm._e(),
        ],
        1
      ),
      _c(
        "win-layer-pop",
        { staticClass: "popup_dim_upload" },
        [
          _vm.uploadComponent
            ? _c(_vm.uploadComponent, {
                tag: "component",
                attrs: {
                  "parent-info": _vm.uploadParam,
                  "category-info": _vm.uploadCategoryInfo,
                  "is-debug": true,
                },
                on: {
                  close: _vm.closeUploadPopup,
                  "set-dext-file-info": _vm.uploadCallbackFunc,
                },
              })
            : _vm._e(),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("colgroup", [
      _c("col", { staticStyle: { width: "5%" } }),
      _c("col", { staticStyle: { width: "8%" } }),
      _c("col", { staticStyle: { width: "12%" } }),
      _c("col", { staticStyle: { width: "11%" } }),
      _c("col", { staticStyle: { width: "10%" } }),
      _c("col", { staticStyle: { width: "8%" } }),
      _c("col", { staticStyle: { width: "8%" } }),
      _c("col", { staticStyle: { width: "8%" } }),
      _c("col", { staticStyle: { width: "10%" } }),
      _c("col", { staticStyle: { width: "20%" } }),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("tr", [
      _c(
        "th",
        { staticClass: "bor-left", attrs: { scope: "col", colspan: "4" } },
        [_vm._v("CAS No. Information")]
      ),
      _c("th", { attrs: { scope: "col", colspan: "3" } }, [_vm._v("RATIO(%)")]),
      _c("th", { attrs: { scope: "col", colspan: "2" } }),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("colgroup", [
      _c("col", { staticStyle: { width: "15%" } }),
      _c("col", { staticStyle: { width: "35%" } }),
      _c("col", { staticStyle: { width: "15%" } }),
      _c("col", { staticStyle: { width: "auto" } }),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }