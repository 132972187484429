<template>
  <div class="select_opt_area dg_area" :id="'dg_area_' + cntrSeq">
    <div class="select_opt_area mt10">
      <table
        v-for="(vo, idx) in dgInfo.containerDGList"
        :key="'tbl_dg' + idx"
        class="tbl_col t2"
      >
        <colgroup>
          <col style="width:5%">
          <col style="width:8%">
          <col style="width:12%">
          <col style="width:11%">
          <col style="width:10%">
          <col style="width:8%">
          <col style="width:8%">
          <col style="width:8%">
          <col style="width:10%">
          <col style="width:20%">
        </colgroup>
        <thead>
          <tr>
            <th
              v-if="idx === 0"
              scope="col"
            >
              <button
                type="button"
                class="tbl_icon plus"
                @click="addContainerDG()"
                v-if="canAddDg()"
              >
                plus
              </button>
            </th>
            <td
              v-if="idx !== 0"
              scope="col"
            />
            <th scope="col">{{ $t('msg.CMBA100.00116') }}</th> <!-- No. -->
            <th scope="col">{{ $t('msg.CSBK100.349') }}</th><!-- UN No. -->
            <th scope="col">{{ $t('msg.CSBK100.351') }}</th> <!-- Class -->
            <th scope="col">{{ $t('msg.CSBK100.350') }}</th> <!-- Packing Group -->
            <th colspan="3" scope="col">{{ $t('msg.CSBK100.352') }}</th> <!-- Proper Shipping Name -->
            <th colspan="2" scope="col">{{ $t('msg.CSBK100.353') }}</th> <!-- Segregation  Group -->
          </tr>
        </thead>
        <tbody>
          <tr>
            <td
              class="tac"
              rowspan="11"
            >
              <button
                type="button"
                class="tbl_icon minus"
                @click="removeContainerDG(idx)"
                v-if="canAddDg()"
              >
                minus
              </button>
            </td>
            <td class="tac">
              {{ idx + 1 }}
            </td>
            <td>
              <div class="search_btn">
                <input
                  v-model="vo.imdunNo"
                  :id="'imdunNo_' + cntrSeq + '_' + idx"
                  class="inp_responsive_space3"
                  type="text"
                  readonly
                  @input="checkValidationDG('imdunNo', idx)"
                  @click.prevent="getUnnoInfo(idx)"
                >
                <span>
                  <button
                    class="search_btn01"
                    @click.prevent="getUnnoInfo(idx)"
                  />
                </span>
              </div>
            </td>
            <td>
              <input
                v-model="vo.imoClasCd"
                :id="'imoClasCd_' + cntrSeq + '_' + idx"
                type="text"
                readonly
              >
            </td>
            <td>
              <select
                :id="'dgPkgGrpCd_' + cntrSeq + '_' + idx"
                v-model="vo.dgPkgGrpCd"
                disabled
                @change="checkValidationDG('dgPkgGrpCd', idx)"
              >
                <option value=" ">-----</option>
                <option value="01">III</option>
                <option value="02">II</option>
                <option value="03">I</option>
                <option value="04">-</option>
              </select>
            </td>
            <td colspan="3">
              {{ vo.dsc }}
            </td>
            <td colspan="2">
              <select
                :id="'imdgGrpCd_' + cntrSeq + '_' + idx"
                v-model="vo.imdgGrpCd"
                disabled초
                @change="checkValidationDG('imdgGrpCd', idx)"
              >
                <option v-for="cvo in commonCodes['VS044']" :key="cvo.cd" :value="cvo.cd">{{ cvo.cdNm }}</option>
              </select>
            </td>
          </tr>
          <tr>
            <!-- Full/Residue -->
            <th class="bor-left" scope="col">{{ $t('msg.CSBK100.354') }}<br>{{ $t('msg.CSBK100.355') }}</th>
            <th scope="col">{{ $t('msg.CSBK100.356') }}</th> <!-- F/P -->
            <th scope="col">{{ $t('msg.CSBK100.387') }}</th> <!-- GRS Weight -->
            <th scope="col">{{ $t('msg.CSBK100.358') }}</th> <!-- Net Weight -->
            <th scope="col">{{ $t('msg.CSBK100.359') }}</th> <!-- Marine Pollutant -->
            <th scope="col">{{ $t('msg.CSBK100.361') }}</th> <!-- Ems F -->
            <th scope="col">{{ $t('msg.CSBK100.361') }}</th> <!-- Ems S -->
            <th colspan="2" scope="col">{{ $t('msg.CSBK100.362') }}</th> <!-- Outer P'KG Q'TY /TYPE -->
          </tr>
          <tr>
            <td class="bor-left">
              <select
                :id="'dgRsdCd_' + cntrSeq + '_' + idx"
                v-model="vo.dgRsdCd"
                @change="checkValidationDG('dgRsdCd', idx)"
              >
                <option value="">----------</option>
                <option value="01">Full</option>
                <option value="02">Residue</option>
              </select>
            </td>
            <td class="tal">
              <e-input-number
                :id="'flsTmpr_' + cntrSeq + '_' + idx"
                v-model="vo.flsTmpr"
                :is-minus="true"
                input-class="col_5"
                :value="vo.flsTmpr"
                :point="2"
                @input="checkValidationDG('flsTmpr', idx)"
                maxlength="5"
              >
              </e-input-number>
              <select
                :id="'tmprUnitCd_' + cntrSeq + '_' + idx"
                v-model="vo.tmprUnitCd"
                class="col_4"
              >
                <option value="C">℃</option>
                <option value="F">℉</option>
              </select>
            </td>
            <td class="tal">
              <e-input-number
                :id="'grsWt_' + cntrSeq + '_' + idx"
                v-model="vo.grsWt"
                :is-comma="true"
                :point="3"
                input-class="col_6"
                :value="vo.grsWt"
                @input="checkValidationDG('grsWt', idx)"
                :readonly="parentInfo.cntrTypCd == 'TK'"
                :maxlength="16"
              >
              </e-input-number> kg
            </td>
            <td class="tal">
              <e-input-number
                :id="'netWt_' + cntrSeq + '_' + idx"
                v-model="vo.netWt"
                :is-comma="true"
                :point="3"
                input-class="col_7"
                :value="vo.netWt"
                @input="checkValidationDG('netWt', idx)"
                :maxlength="16"
              >
              </e-input-number> kg
            </td>
            <td>
              <select
                v-model="vo.mpltCd"
                :id="'mpltCd_' + cntrSeq + '_' + idx"
                @change="checkValidationDG('mpltCd', idx);checkValidationDG('tchlNm', idx);"
                disabled
              >
                <option value="">{{ $t('msg.CSBK100.062') }}</option>
                <option value="Y">Y</option>
                <option value="N">N</option>
              </select>
            </td>
            <td>
              <select
                :id="'emsFeCd_' + cntrSeq + '_' + idx"
                v-model="vo.emsFeCd"
              >
                <option
                  v-for="cvo in emsFeCdList"
                  :key="cvo"
                  :value="cvo"
                >
                  {{ cvo }}
                </option>
              </select>
            </td>
            <td>
              <select
                :id="'emsSeCd_' + cntrSeq + '_' + idx"
                v-model="vo.emsSeCd"
              >
                <option
                  v-for="cvo in emsSeCdList"
                  :key="cvo"
                  :value="cvo"
                >
                  {{ cvo }}
                </option>
              </select>
            </td>
            <td>
              <e-input-number
                v-model="vo.dgPkgQty"
                :is-comma="true"
                :id="'dgPkgQty_' + cntrSeq + '_' + idx"
                input-class="dgpkg"
                :value="vo.dgPkgQty"
                @input="checkValidationDG('dgPkgQty', idx)"
                :maxlength="12"
                :readonly="parentInfo.cntrTypCd == 'TK'"
              >
              </e-input-number>
            </td>
            <td>
              <select
                :id="'dgMatlCd_' + cntrSeq + '_' + idx"
                v-model="vo.dgMatlCd"
                @change="changeDgOuter(vo, idx)"
              >
                <option value="">-------------------------------</option>
                <option v-for="cvo in vo.outerPkgList" :key="cvo.cd" :value="cvo.cd">{{ cvo.cdNm }}</option>
              </select>
            </td>
          </tr>
          <tr>
            <th class="bor-left" colspan="4" scope="col">{{ $t('msg.CSBK100.363') }}</th> <!-- Technical Name -->
            <th scope="col">{{ $t('msg.CSBK100.364') }}</th> <!-- Limited Quantity -->
            <th colspan="2" scope="col">{{ $t('msg.CSBK100.365') }}</th> <!-- SAPT -->
            <th colspan="2" scope="col">{{ $t('msg.CSBK100.366') }}</th> <!-- Inner P'KG Q'TY /TYPE -->
          </tr>
          <tr>
            <td class="bor-left tac" colspan="4">
              <input
                v-show="vo.mpltCd === 'Y' || vo.tchlNmMdtyYn === 'Y'"
                :id="'tchlNm_' + cntrSeq + '_' + idx"
                v-model="vo.tchlNm"
                type="text"
                @change="handleChange()"
                disabled
              >
              <span v-show="vo.mpltCd !== 'Y' && vo.tchlNmMdtyYn !== 'Y'">-</span>
            </td>
            <td class="tac">
              <input
                :id="'lqYn_' + cntrSeq + '_' + idx"
                v-model="vo.lqYn"
                type="checkbox"
                true-value="Y"
                false-value="N"
                :checked="vo.lqYn === 'Y'"
              >
              <label :for="'lqYn_' + cntrSeq + '_' + idx"><span class="offscreen">L/Q YN</span></label>
            </td>
            <td>
              <e-input-number
                :id="'saptTmpr_' + cntrSeq + '_' + idx"
                v-model="vo.saptTmpr"
                :is-comma="true"
                input-class="saptTmpr"
                :value="vo.saptTmpr"
              >
              </e-input-number>
            </td>
            <td>
              <select
                :id="'saptTmprUnitCd_' + cntrSeq + '_' + idx"
                v-model="vo.saptTmprUnitCd"
                readonly
              >
                <option value="C">℃</option>
                <option value="F">℉</option>
              </select>
            </td>
            <td>
              <e-input-number
                v-model="vo.isdDgPkgQty"
                :is-comma="true"
                :id="'isdDgPkgQty_' + cntrSeq + '_' + idx"
                input-class="isdDgpkg"
                :value="vo.isdDgPkgQty"
                @input="checkValidationDG('isdDgPkgQty', idx)"
              >
              </e-input-number>
            </td>
            <td>
              <select
                v-model="vo.dgCatCd"
                :id="'dgCatCd_' + cntrSeq + '_' + idx"
                @change="checkValidationDG('dgCatCd', idx)"
              >
                <option v-for="cvo in vo.innerPkgList" :key="cvo.cd" :value="cvo.cd">{{ cvo.cdNm }}</option>
              </select>
            </td>
          </tr>
          <tr>
            <th class="bor-left" scope="col" colspan="2">{{ $t('msg.CSBK100.367') }}</th> <!-- Name of Emergency PIC -->
            <th scope="col">{{ $t('msg.CSBK100.368') }}</th> <!-- Country Code -->
            <th scope="col">{{ $t('msg.CSBK100.369') }}</th> <!-- TEL No. -->
            <th class="tal" scope="col" colspan="5"></th>
          </tr>
          <tr>
            <td
              class="bor-left"
              colspan="2"
            >
              <input
                :id="'emrPicNm_' + cntrSeq + '_' + idx"
                v-model="vo.emrPicNm"
                type="text"
                @keyup="checkValidationDG('emrPicNm', idx)"
              >
            </td>
            <td>
              <e-input-number
                :id="'emrTelCtrNo_' + cntrSeq + '_' + idx"
                v-model="vo.emrTelCtrNo"
                type="text"
                @keyup="checkValidationDG('emrTelCtrNo', idx)"
                maxlength="4"
              >
              </e-input-number>
            </td>
            <td>
              <e-input-number
                :id="'emrTelNo_' + cntrSeq + '_' + idx"
                v-model="vo.emrTelNo"
                :value="vo.emrTelNo"
                :is-phone="true"
                @input="checkValidationDG('emrTelNo', idx)"
                :maxlength="26"
              >
              </e-input-number>
            </td>
            <td class="bor-left bor-r0" colspan="5">
            </td>
          </tr>
          <tr>
            <th class="bor-left" scope="col" colspan="4">Additional Information</th>
            <th class="tal" scope="col" colspan="5">
              <span v-if="vo.addInfoMsg !== undefined && vo.addInfoMsg !== ''">Q. {{ vo.addInfoMsg }}</span>
            </th>
          </tr>
          <tr>
            <td class="bor-left bor-r0" colspan="9">
              <select
                v-if="vo.addInfoMsgList !== undefined && vo.addInfoMsgList !== null && vo.addInfoMsgList.length > 0"
                :id="'repsCont_' + cntrSeq + '_' + idx"
                v-model="vo.repsCont"
                @change="checkValidationDG('repsCont', idx)"
              >
                <option value="">=== Select the answer ===</option>
                <option v-for="(cvo, i) in vo.addInfoMsgList" :key="i" :value="cvo.rspsCont">{{ cvo.rspsCont }}</option>
              </select>
            </td>
          </tr>
          <tr>
            <th class="bor-left" scope="col" colspan="4">CAS No. Information</th>
            <th scope="col" colspan="3">RATIO(%)</th>
            <th scope="col" colspan="2"></th>
          </tr>
          <tr>
            <td
              class="bor-left bor-r0"
              colspan="4"
            >
              <div>
                <div v-for="(cvo, i) in vo.casNoList" :key="i + forceUpdate" class="tbl_form cas_area">
                  <span class="dv wid20">
                    <button
                      v-if="i === 0"
                      type="button"
                      class="tbl_icon plus"
                      @click="addCasNo(idx)"
                    >
                      plus
                    </button>
                    <button
                      v-if="i !== 0"
                      type="button"
                      class="tbl_icon minus"
                      @click="removeCasNo(idx, i)"
                    >
                      minus
                    </button>
                  </span>
                  <span class="dv position_relative">
                    <!-- <input
                      v-model="cvo.casNo"
                      type="text"
                      :id="'casNo_' + idx + '_' + i"
                      @keyup="checkValidationCasNo(cvo, idx, i)"
                    > -->
                    <e-input-number
                      v-model="cvo.casNo"
                      :id="'casNo_' + idx + '_' + i"
                      maxlength="10"
                      @input="checkValidationCasNo(cvo, idx, i)"
                    >
                    </e-input-number>
                  </span>
                  <span class="dv"></span>
                </div>
              </div>
            </td>
            <td
              class="bor-left bor-r0"
              colspan="3"
            >
              <template>
                <div v-for="(cvo, i) in vo.casNoList" :key="i" class="tbl_form cas_area">
                  <e-input-number
                    v-model="cvo.rto"
                    :id="'rto_' + idx + '_' + i"
                    :point="2"
                    :digit-max="3"
                    maxlength="6"
                  >
                  </e-input-number>
                </div>
              </template>
            </td>
            <td colspan="2"></td>
          </tr>
        </tbody>
      </table>
      <table class="tbl_col t2 mt10">
        <colgroup>
          <col style="width:15%">
          <col style="width:35%">
          <col style="width:15%">
          <col style="width:auto">
        </colgroup>
        <tbody>
          <tr v-if="lang === 'ENG' && vanningDtYn === 'Y'">
            <th scope="row">Vanning Date</th>
            <td colspan="3">
              <div class="input_calendar">
                <e-date-picker
                  v-model="dgInfo.vaniDt"
                  :id="'vaniDt_' + cntrSeq"
                  type="text"
                  placeholder="YYYY-MM-DD"
                >
                </e-date-picker>
              </div>
            </td>
          </tr>
          <tr>
            <th scope="row">File Upload</th>
            <td colspan="3">
              <div class="filebox" :id="'srDgFileBox_'+parentInfo.cntrSeq">
                <a
                  @click="openUploadPopup()"
                  :class="isFileUpload() ? 'blue' : 'gray file'"
                  class="button sm"
                >
                  {{ $t('msg.CSBK100.205') }}
                </a>
              </div>
            </td>
          </tr>
          <tr>
            <th scope="row">Request Remark</th>
            <td colspan="3">
              <textarea v-model="dgInfo.reqConts" @change="handleChange" />
            </td>
          </tr>
          <tr>
            <th scope="row">Status</th>
            <td>
              <span class="span_scg">{{ dgInfo.apvStsNm }}</span>
              <!-- <input type="text"> -->
            </td>
            <th scope="row">Ref No.</th>
            <td>
              <span class="span_scg">{{ dgInfo.scgAppNo }}</span>
              <!-- <input type="text"> -->
            </td>
          </tr>
          <tr>
            <th scope="row">KMTC Remark</th>
            <td colspan="3">
              <textarea
                class="txt_border_none"
                :value="dgInfo.apvCont"
                readonly
              />
            </td>
          </tr>
        </tbody>
      </table>
      <!-- File Upload e -->
    </div>
    <win-layer-pop class="popup_dim_normal">
      <component
        :is="customComponent"
        v-if="customComponent"
        :parent-info="popParams"
        @close="closePoup"
        @selectFunc="setUnnoInfo"
      />
    </win-layer-pop>
    <win-layer-pop class="popup_dim_upload">
      <component
        v-if="uploadComponent"
        :is="uploadComponent"
        @close="closeUploadPopup"
        :parent-info="uploadParam"
        @set-dext-file-info="uploadCallbackFunc"
        :category-info="uploadCategoryInfo"
        :is-debug="true"
      />
    </win-layer-pop>
  </div>
</template>

<script>
import commons from '@/api/services/commons'
import unno from '@/api/rest/trans/unno'
import trans from '@/api/rest/trans/trans'
import transCommon from '@/api/rest/trans/transCommon'
import EInputNumber from '@/components/common/EInputNumber'
import DgOogCerti from '@/api/rest/trans/dgOogCerti'
import srInquiry from '@/api/services/srInquiry'

export default {
  name: 'BookingSrTableDg',
  components: {
    EInputNumber,
    'unno-pop': () => import('@/pages/trans/popup/UnnoPop'),
    'win-layer-pop': () => import('@/pages/trans/popup/WinLayerPop'),
    'doc-upload-pop': () => import('@/pages/trans/popup/DocUploadPop')
  },
  props: {
    parentInfo: {
      type: Object,
      default: null
    },
    parentIdx: {
      type: Number,
      default: 0
    },
    casNoPortYn: {
      type: String,
      default: 'N'
    },
    telCtrNo: {
      type: String,
      default: ''
    },
    disabledYn: {
      type: String,
      default: 'N'
    },
    unnoDisabledYn: {
      type: String,
      default: 'N'
    },
    index: {
      type: Number,
      default: 0
    },
    saveFlag: {
      type: String,
      default: 'ADD'
    },
    detailBkgNo: {
      type: String,
      default: ''
    }
  },
  data () {
    return {
      forceUpdate: 0,
      lang: 'CHN',
      uploadComponent: null,
      uploadType: '',
      uploadParam: [],
      uploadCategoryInfo: [],
      popParams: {},
      customComponent: null,
      emsFeCdList: [],
      emsSeCdList: [],
      commonCodes: {},
      vanningDtYn: 'N',
      cntrTypCd: '',
      cntrSzCd: '',
      cntrSeq: '',
      cgoTypCd: '',
      dgInfo: {
        vaniDt: '',
        repsCont: '',
        reqRno: '',
        uploadFileList: [],
        containerDGList: [
          {
            dgSeq: '1',
            imdunNo: '',
            imdgSeq: '',
            imoClasCd: '',
            sbRiskVal1: '',
            sbRiskVal2: '',
            sbRiskVal3: '',
            flsTmpr: '',
            tmprUnitCd: 'C',
            txtPackage: '',
            grsWt: '',
            netWt: '',
            dgPkgGrpCd: '',
            emrTelCtrNo: this.telCtrNo,
            emrTelNo: '',
            tchlNm: '',
            dgPkgQty: '',
            dgMatlCd: '',
            isdDgPkgQty: '',
            dgCatCd: '',
            mpltYn: '',
            emsFeCd: '',
            emsSeCd: '',
            /* lqYn: '', */
            imdgGrpCd: '',
            imdgGrpYn: '',
            dgRsdCd: '',
            saptTmprUnitCd: '', /* SAPT 단위 */
            sp386Yn: '',
            saptTmpr: '',
            addInfoMsg: '',
            addInfoMsgList: [],
            casNoList: [
              {
                casNo: '',
                rto: ''
              }
            ],
            repsCont: ''
          }
        ]
      }
    }
  },
  created () {
    this.initSelect()
    this.initParams()
    this.initCommCodes()
  },
  methods: {
    handleChange () {
      console.log('@@@@ enter handleChange')
      console.log({
        ...this.dgInfo
      })

      this.$emit('update', {
        ...this.dgInfo
      }, this.index)
    },
    async initCommCodes () {
      const arrCdId = ['VS044', 'VS038']
      this.commonCodes = await commons.getCommonsCodesCommon(arrCdId)

      // OOG 첨부파일 카테고리
      const uploadCategoryInfo = this.commonCodes.VS038

      this.uploadCategoryInfo = uploadCategoryInfo.map(tvo => {
        const vo = {
          category: tvo.cdNm,
          value: tvo.cd
        }
        return vo
      })
    },
    initSelect () {
      for (let i = 65; i <= 74; i++) { // 대문자 A-J
        this.emsFeCdList.push(String.fromCharCode(i))
      }

      for (let i = 65; i <= 90; i++) { // 대문자 A-Z
        this.emsSeCdList.push(String.fromCharCode(i))
      }
    },
    initParams () {
      const parentInfo = this.parentInfo
      const dgInfo = this.dgInfo

      dgInfo.reqConts = parentInfo.reqConts
      dgInfo.repsCont = parentInfo.repsCont
      dgInfo.vaniDt = parentInfo.vaniDt
      dgInfo.reqRno = parentInfo.reqRno || ''
      dgInfo.uploadFileList = parentInfo.uploadFileList || []
      dgInfo.apvStsNm = parentInfo.apvStsNm || ''
      dgInfo.scgAppNo = parentInfo.scgAppNo || ''
      dgInfo.apvCont = parentInfo.apvCont || ''

      this.cntrTypCd = parentInfo.cntrTypCd
      this.cntrSzCd = parentInfo.cntrSzCd
      this.cntrSeq = parentInfo.cntrSeq
      this.cgoTypCd = parentInfo.cgoTypCd

      const containerDGList = parentInfo.containerDGList

      const TH = this
      if (containerDGList !== undefined && containerDGList !== null && containerDGList.length > 0) {
        dgInfo.containerDGList = containerDGList

        dgInfo.containerDGList.forEach(obj => {
          const casNoList = obj.casNoList

          if (casNoList !== undefined && casNoList.length > 0) {
            TH.$set(obj, 'casNoList', casNoList)
          }
        })

        this.$nextTick(() => {
          TH.initDGInfo()
        })
      }

      if (this.cntrTypCd === 'TK' && this.cgoTypCd === '01') {
        dgInfo.containerDGList[0].dgPkgQty = '1'
      }
    },
    initDGInfo () {
      const dgInfo = this.dgInfo
      const containerDGList = dgInfo.containerDGList
      const cntrSeq = this.cntrSeq

      containerDGList.forEach((obj, idx) => {
        if (obj.tchlNmMdtyYn === 'Y' || obj.mpltCd === 'Y') {
          document.querySelector('#emsFeCd_' + cntrSeq + '_' + idx).disabled = false
          document.querySelector('#emsSeCd_' + cntrSeq + '_' + idx).disabled = false
        } else {
          obj.tchlNm = ''
          document.querySelector('#emsFeCd_' + cntrSeq + '_' + idx).disabled = true
          document.querySelector('#emsSeCd_' + cntrSeq + '_' + idx).disabled = true
        }

        if (this.$ekmtcCommon.isEmpty(obj.imdgGrpCd)) {
          if (obj.tchlNmMdtyYn === 'Y') {
            document.querySelector('#imdgGrpCd_' + cntrSeq + '_' + idx).disabled = false
            obj.imdgGrpYn = 'Y'
          } else {
            document.querySelector('#imdgGrpCd_' + cntrSeq + '_' + idx).disabled = true
            obj.imdgGrpYn = 'N'
          }
        } else {
          document.querySelector('#imdgGrpCd_' + cntrSeq + '_' + idx).disabled = true
          obj.imdgGrpYn = 'N'
        }

        if (obj.sp386Yn === 'Y') {
          document.querySelector('#saptTmpr_' + cntrSeq + '_' + idx).disabled = false
          document.querySelector('#saptTmprUnitCd_' + cntrSeq + '_' + idx).disabled = false
        } else {
          document.querySelector('#saptTmpr_' + cntrSeq + '_' + idx).disabled = true
          document.querySelector('#saptTmprUnitCd_' + cntrSeq + '_' + idx).disabled = true
        }

        /* if (this.cntrTypCd === 'TK') {
          document.querySelector('#lqYn_' + cntrSeq + '_' + idx).disabled = true
        } else if (obj.lmtVal === '0') {
          document.querySelector('#lqYn_' + cntrSeq + '_' + idx).disabled = true
        } else {
          document.querySelector('#lqYn_' + cntrSeq + '_' + idx).disabled = false
        } */

        obj.dgMatlCd = obj.dgMatlCd || ''
        obj.dgCatCd = obj.dgCatCd || ''

        if (obj.imdunNo !== undefined) {
          this.setDgOuter(obj)
          this.setDgInnerList(obj)
          this.setDgAddInfoMsg(obj)
        }
      })
    },
    setDgOuter (dgVo) {
      const TH = this
      const formData = {
        imdunNo: dgVo.imdunNo,
        imdgSeq: dgVo.imdgSeq,
        cntrTypeCd: this.cntrTypCd
      }

      unno.getDgOuterList(formData).then((data) => {
        TH.$set(dgVo, 'outerPkgList', data.data)
      })
    },
    setDgInnerList (dgVo) {
      const params = {
        cntrTypeCd: this.cntrTypCd,
        imdunNo: dgVo.imdunNo,
        imdgSeq: dgVo.imdgSeq,
        outer: dgVo.dgMatlCd
      }

      const def = {
        cd: '',
        cdNm: ''
      }

      unno.getDgInnerList(params).then((data) => {
        const newList = []
        const innerPkgList = data.data

        innerPkgList.forEach(c => {
          newList.push({ ...def, ...c })
        })

        if (newList.length === 0) {
          newList.push({ ...def, ...{ cdNm: '-' } })
        }

        this.$set(dgVo, 'innerPkgList', newList)
      })
    },
    setDgAddInfoMsg (dgVo) {
      const TH = this
      const formData = {
        imdunNo: dgVo.imdunNo,
        imdgSeq: dgVo.imdgSeq
      }

      unno.getDgAddInfoMsg(formData).then((data) => {
        const question = data.data
        if (question !== undefined && question !== null) {
          dgVo.addInfoMsg = question.reqCont
          const seq = question.reqSeq

          if (this.$ekmtcCommon.isNotEmpty(seq)) {
            unno.getDgAddInfoList(seq).then((res) => {
              TH.$set(dgVo, 'addInfoMsgList', res.data)
            })
          }
        }
      })
    },
    async checkValidationDGAll () {
      /* const prefix = [
                      'imdunNo', 'dgPkgGrpCd', 'imdgGrpCd', 'dgMatlCd', 'dgRsdCd', 'lqYn',
                      'repsCont', 'emrPicNm', 'emrTelCtrNo', 'emrTelNo', 'flsTmpr', 'saptTmpr',
                      'grsWt', 'netWt', 'dgPkgQty', 'isdDgPkgQty', 'dgCatCd', 'mpltCd', 'tchlNm', 'casNo'
                    ] */

      const prefix = [
                      'imdunNo', 'dgPkgGrpCd', 'imdgGrpCd', 'dgMatlCd', 'dgRsdCd',
                      'repsCont', 'emrPicNm', 'emrTelCtrNo', 'emrTelNo', 'flsTmpr', 'saptTmpr',
                      'grsWt', 'netWt', 'dgPkgQty', 'isdDgPkgQty', 'dgCatCd', 'mpltCd', 'tchlNm', 'casNo'
                    ]

      const dgList = this.dgInfo.containerDGList

      let isOk = true

      dgList.forEach((c, idx) => {
        prefix.forEach(el => {
          if (!this.checkValidationDG(el, idx)) {
            isOk = false
          }
        })
      })
      return isOk
    },
    checkValidationDG (prefix, idx, flag) {
      console.log('@@@@ enter checkValidationDG')
      let isOk = true
      const cntrSeq = this.cntrSeq
      const frm = document.querySelector('#srForm')
      const idxInfo = cntrSeq + '_' + idx
      const id = prefix + '_' + idxInfo
      const selector = frm.querySelector('#' + id)
      const containerDGInfo = this.dgInfo.containerDGList[idx]

      if (selector !== null) {
        this.$ekmtcCommon.hideErrorTooltip(selector)
      }

      if (prefix === 'imdunNo' && this.$ekmtcCommon.isEmpty(selector.value.trim())) {
        this.$ekmtcCommon.showErrorTooltip(selector, this.$t('msg.CSBK100.147'))
        isOk = false
      }

      if (prefix === 'dgPkgGrpCd' && this.$ekmtcCommon.isEmpty(selector.value.trim())) {
        this.$ekmtcCommon.showErrorTooltip(selector, this.$t('msg.CSBK100.147'))
        selector.disabled = false
        isOk = false
      }
      if (prefix === 'imdgGrpCd' &&
          this.$ekmtcCommon.isEmpty(selector.value.trim()) &&
          (containerDGInfo.tchlNmMdtyYn === 'Y' || containerDGInfo.mpltYn === 'Y') &&
          !selector.disabled
      ) {
        this.$ekmtcCommon.showErrorTooltip(selector, 'Please kindly select Segregation Group of UNNO ' + containerDGInfo.imdunNo + ' according to 3.1.4.2 and 5.4.1.4.11.1 of IMDG')
        isOk = false
      }

      // Outer P'KG TYPE
      // if (prefix === 'dgMatlCd' && this.$ekmtcCommon.isEmpty(selector.value.trim())) {
      //   this.$ekmtcCommon.showErrorTooltip(selector, this.$t('msg.CSBK100.147'))
      //   isOk = false
      // }

      if (prefix === 'dgRsdCd' && this.$ekmtcCommon.isEmpty(selector.value.trim())) {
        this.$ekmtcCommon.showErrorTooltip(selector, this.$t('msg.CSBK100.147'))
        isOk = false
      }
      const addInfoMsgList = containerDGInfo.addInfoMsgList
      if (prefix === 'repsCont' && addInfoMsgList !== undefined && addInfoMsgList.length > 0) {
        if (this.$ekmtcCommon.isEmpty(selector.value.trim())) {
          this.$ekmtcCommon.showErrorTooltip(selector, this.$t('msg.CSBK100.147'))
          isOk = false
        } else {
          const answerVo = addInfoMsgList.find(tvo => tvo.rspsCont === selector.value)
          if (answerVo !== undefined && answerVo.rspsUseYn === 'N') {
            const msg = 'Cargo Seq No. ' +
                        containerDGInfo.dgSeq +
                        ' / UN No. ' + containerDGInfo.imdunNo +
                        'cannot be saved due to KMTC prohibited or illegal information by IMDG Code.\n-' +
                        answerVo.rspsCont
            containerDGInfo.repsCont = ''
            this.$ekmtcCommon.alertDefault(msg)
            this.$ekmtcCommon.showErrorTooltip(selector, this.$t('msg.CSBK100.147'))
            isOk = false
          }
        }
      }
      if (prefix === 'emrPicNm' && this.$ekmtcCommon.isEmpty(selector.value.trim())) {
        this.$ekmtcCommon.showErrorTooltip(selector, this.$t('msg.CSBK100.147'))
        isOk = false
      }
      if (prefix === 'emrTelCtrNo') {
        if (this.$ekmtcCommon.isEmpty(selector.value.trim())) {
          this.$ekmtcCommon.showErrorTooltip(selector, this.$t('msg.CSBK100.147'))
          isOk = false
        } else if (!this.$ekmtcCommon.checkTextByte(selector.value, 4)) {
          this.$ekmtcCommon.showErrorTooltip(selector, 'Too large text to create S/R.')
          isOk = false
        }
      }
      if (prefix === 'emrTelNo') {
        if (this.$ekmtcCommon.isEmpty(selector.value.trim())) {
          this.$ekmtcCommon.showErrorTooltip(selector, this.$t('msg.CSBK100.147'))
          isOk = false
        } else if (selector.value.substring(0, 1) === '0') {
          this.$ekmtcCommon.showErrorTooltip(selector, this.$t('js.booking.028'))
          isOk = false
        }
      }

      if (prefix === 'mpltCd') {
        if (this.$ekmtcCommon.isEmpty(selector.value.trim())) {
          this.$ekmtcCommon.showErrorTooltip(selector, this.$t('msg.CSBK100.147'))
          isOk = false
        } else {
          this.$set(this.dgInfo.containerDGList, idx, this.dgInfo.containerDGList[idx])
        }
      }

      if (prefix === 'tchlNm') {
        if ((containerDGInfo.mpltCd === 'Y' || containerDGInfo.tchlNmMdtyYn === 'Y') && this.$ekmtcCommon.isEmpty(selector.value.trim())) {
          this.$ekmtcCommon.showErrorTooltip(selector, this.$t('msg.CSBK100.147'))
          isOk = false
        }
      }

      if (prefix === 'flsTmpr' &&
          this.$ekmtcCommon.isEmpty(selector.value.trim()) &&
          containerDGInfo.imoClasCd === '3'
        ) {
          this.$ekmtcCommon.showErrorTooltip(selector, this.$t('msg.CSBK100.147'))
          isOk = false
      } else if (prefix === 'flsTmpr' && this.$ekmtcCommon.isNotEmpty(selector.value.trim()) && flag !== 'SAVE') {
        const isFlsTmprOk = this.checkValidationFlsTmpr(idx)

        if (!isFlsTmprOk) {
          isOk = false
        }
      }

      //Gross Weight, Net Weight
      if (prefix === 'grsWt' || prefix === 'netWt') {
        if (this.$ekmtcCommon.isEmpty(selector.value.trim())) {
          this.$ekmtcCommon.showErrorTooltip(selector, this.$t('msg.CSBK100.147'))
          isOk = false
        } else {
          if (this.cntrTypCd === 'TK') {
            containerDGInfo.grsWt = containerDGInfo.netWt
          }
          let grsWt = Number(containerDGInfo.grsWt === '' ? 0 : containerDGInfo.grsWt)
          let netWt = Number(containerDGInfo.netWt)

          if ((prefix === 'grsWt' && grsWt > 40000) || (prefix === 'netWt' && netWt > 40000) || grsWt < netWt) {
            this.$ekmtcCommon.showErrorTooltip(selector, 'Please kindly check GWT and NET.\nAnd, GWT and NET are must be filled out as per one container.')
            isOk = false
          } else {
            this.$ekmtcCommon.hideErrorTooltip(frm.querySelector('#grsWt_' + idxInfo))
            this.$ekmtcCommon.hideErrorTooltip(frm.querySelector('#netWt_' + idxInfo))
          }
        }
      }

      if (prefix === 'dgPkgQty' && (this.$ekmtcCommon.isEmpty(selector.value.trim()) || selector.value.trim() === '0')) {
        this.$ekmtcCommon.showErrorTooltip(selector, this.$t('msg.CSBK100.147'))
        isOk = false
      }

      if (prefix === 'isdDgPkgQty' || prefix === 'dgCatCd') {
        const tempSelector = frm.querySelector('#isdDgPkgQty_' + idxInfo)
        const dgPkgQty = containerDGInfo.dgPkgQty === '' ? 0 : Number(containerDGInfo.dgPkgQty)
        const isdDgPkgQty = containerDGInfo.isdDgPkgQty === '' ? 0 : Number(containerDGInfo.isdDgPkgQty)

        if (containerDGInfo.dgCatCd !== '-') {
          if (containerDGInfo.isdDgPkgQty === '') {
            this.$ekmtcCommon.showErrorTooltip(tempSelector, this.$t('msg.CSBK100.147'))
            isOk = false
          } else if (isdDgPkgQty === 0) {
            this.$ekmtcCommon.showErrorTooltip(tempSelector, 'Please input correct inner package quantity not zero.')
            isOk = false
          } else if (dgPkgQty > isdDgPkgQty) {
            this.$ekmtcCommon.showErrorTooltip(tempSelector, 'Please check again package type, quantity corresponding cargo weight.')
            isOk = false
          }
        } else {
          if (isdDgPkgQty !== 0 && isdDgPkgQty > 0) {
            this.$ekmtcCommon.showErrorTooltip(tempSelector, 'Please check Inner Package Type or Quantity.')
            isOk = false
          }
        }
      }

      // if (prefix === 'dgCatCd' && (this.$ekmtcCommon.isEmpty(selector.value.trim()) || selector.value.trim() === '-')) {
      //   this.$ekmtcCommon.showErrorTooltip(selector, this.$t('msg.CSBK100.147'))
      //   isOk = false
      // }

      if (prefix === 'casNo' && this.casNoPortYn === 'Y') {
        containerDGInfo.casNoList.forEach((el, i) => {
          if (!this.checkValidationCasNo(el, idx, i)) {
            isOk = false
          }
        })
      }

      /* if (prefix === 'lqYn' && containerDGInfo.lqYn !== 'Y') {
        let strImdgGrpCd = ''
        this.dgInfo.containerDGList.forEach(dg => {
          strImdgGrpCd += dg.imdgGrpCd + ','
        })

        if (strImdgGrpCd.indexOf('01') > -1 && strImdgGrpCd.indexOf('18') > -1) {
          this.$ekmtcCommon.showErrorTooltip(selector, 'Segmentation Group ACIDS and ALKALS are not intermixed.')
          isOk = false
        }
      } */

      if (prefix === 'saptTmpr') {
        if (this.$ekmtcCommon.isEmpty(selector.value.trim())) {
          if (containerDGInfo.sp386Yn === 'Y') {
            this.$ekmtcCommon.showErrorTooltip(selector, this.$t('msg.CSBK100.147'))
            isOk = false
          }
        } else {
          const confirmMsg = 'UN No. ' + containerDGInfo.imdunNo + '(SAPT ' + selector.value + this.$t('msg.CSBK100.196')
          if (Number(selector.value) <= 45) {
            let msg = ''
            if (this.cntrTypCd === 'GP' || this.cntrTypCd === 'HC') {
              msg = 'UN No. ' + containerDGInfo.imdunNo +
                    '(SAPT ' + selector.value + '\'C)' + this.$t('msg.CSBK100.197_1') + this.$t('msg.CSBK100.197')
            } else if (this.cntrTypCd === 'TK') {
              msg = this.$t('msg.CSBK100.197')
            } else {
              msg = this.$t('msg.CSBK100.198')
            }

            this.$ekmtcCommon.showErrorTooltip(selector, msg)
            isOk = false
          } else if (Number(selector.value) > 45 && Number(value) <= 50) {
            if (this.cntrTypCd === 'GP' || this.cntrTypCd === 'HC') {
              msg = this.$t('msg.CSBK100.197')
            } else if (this.cntrTypCd === 'TK') {
              if (!confirm(confirmMsg)) {
                return false
              }
            } else {
              msg = this.$t('msg.CSBK100.198')
            }

            this.$ekmtcCommon.showErrorTooltip(selector, msg)
            isOk = false
          } else if (Number(selector.value) > 50 && Number(selector.value) <= 75) {
            if (this.cntrTypCd === 'GP' || this.cntrTypCd === 'HC' || this.cntrTypCd === 'TK') {
              if (!confirm(confirmMsg)) {
                return false
              }
            } else {
              msg = this.$t('msg.CSBK100.198')
              this.$ekmtcCommon.showErrorTooltip(selector, msg)
              isOk = false
            }
          } else if (Number(selector.value) > 75) {
            if (this.cntrTypCd !== 'GP' && this.cntrTypCd !== 'HC' && this.cntrTypCd !== 'TK') {
              msg = this.$t('msg.CSBK100.198')
              this.$ekmtcCommon.showErrorTooltip(selector, msg)
              isOk = false
            }
          }
        }
      }

      // commit
      this.handleChange()

      return isOk
    },
    async checkValidationFlsTmpr (idx) {
      const frm = document.querySelector('#srForm')
      const dg = this.dgInfo.containerDGList[idx]
      const cntrSeq = this.cntrSeq
      let isOk = true
      const selector = frm.querySelector('#flsTmpr_' + cntrSeq + '_' + idx)

      if (this.$ekmtcCommon.isNotEmpty(dg.imdunNo)) {
        await transCommon.dgFPointCheck(dg.imdunNo).then(res => {
          const result = res.data
          let strFlsTmpr = ''
          if (result !== undefined) {
            if (result !== 'N' && result.indexOf('@') > -1) {
              strFlsTmpr = Number(result.split('@')[0])
              const endFlsTmpr = Number(result.split('@')[1])
              const flsTmpr = Number(dg.flsTmpr)

              if (flsTmpr !== 0 && (strFlsTmpr > flsTmpr || endFlsTmpr < flsTmpr)) {
                this.$ekmtcCommon.showErrorTooltip(selector, 'Please check again Flash Point.' + strFlsTmpr + '℃~' + endFlsTmpr + '℃')
                isOk = false
              }
            }
          }

          if (this.$ekmtcCommon.isEmpty(strFlsTmpr) && this.$ekmtcCommon.isNotEmpty(dg.flsTmpr)) {
            const clasCd = this.$ekmtcCommon.isNotEmpty(dg.imoClasCd) ? dg.imoClasCd.substring(0, 1) : ''
            const sbRiskVal1 = this.$ekmtcCommon.isNotEmpty(dg.sbRiskVal1) ? dg.sbRiskVal1.substring(0, 1) : ''
            const sbRiskVal2 = this.$ekmtcCommon.isNotEmpty(dg.sbRiskVal2) ? dg.sbRiskVal2.substring(0, 1) : ''
            const sbRiskVal3 = this.$ekmtcCommon.isNotEmpty(dg.sbRiskVal3) ? dg.sbRiskVal3.substring(0, 1) : ''

            if (clasCd === '3' || sbRiskVal1 === '3' || sbRiskVal2 === '3' || sbRiskVal3 === '3') {
              if ((dg.dgPkgGrpCd === '02' || dg.dgPkgGrpCd === '03') && Number(dg.flsTmpr) >= 23) {
                this.$ekmtcCommon.showErrorTooltip(selector, 'Please kindly check Flash Point according Packing Group.\nIn case of Class 3 (Flammable Liquid), please refer as below.\nPacking Group I or II : Flash Point <23 °C')
                isOk = false
              }
              if (dg.dgPkgGrpCd === '01' && (Number(dg.flsTmpr) < 23 || Number(dg.flsTmpr) > 60)) {
                this.$ekmtcCommon.showErrorTooltip(selector, 'Please kindly check Flash Point according Packing Group.\nIn case of Class 3 (Flammable Liquid), please refer as below.\nPacking Group III :  Flash Point  ≥23 to ≤60 °C')
                isOk = false
              }
            } else if ((clasCd !== '2' && clasCd !== '4') && (sbRiskVal1 !== '2' && sbRiskVal1 !== '4') &&
                (sbRiskVal2 !== '2' && sbRiskVal2 !== '4') && (sbRiskVal3 !== '2' && sbRiskVal3 !== '4') && Number(dg.flsTmpr) < 60) {
              this.$ekmtcCommon.showErrorTooltip(selector, 'Please check again F.P as Celcius(℃).\nIf F.P is less than 60℃, it should be classfied Class 3 DG Cargo.')
              isOk = false
            }
          }
        })
      }

      return isOk
    },
    checkValidationCasNo (el, idx, i) {
      let isOk = true
      const frm = document.querySelector('#srForm')
      const casSelector = frm.querySelector('#casNo_' + idx + '_' + i)
      this.$ekmtcCommon.hideErrorTooltip(casSelector)
      if (this.$ekmtcCommon.isEmpty(el.casNo)) {
        this.casNoPortYn === 'Y' && this.$ekmtcCommon.showErrorTooltip(casSelector, this.$t('msg.CSBK100.147'))
        isOk = false
      }

      // commit
      this.handleChange()

      return isOk
    },
    async checkValidationDGMax () {
      const frm = document.querySelector('#srForm')
      const containerDGList = this.dgInfo.containerDGList
      const cntrSzCd = this.cntrSzCd
      const cntrTypCd = this.cntrTypCd
      const cntrSeq = this.cntrSeq

      for (const dg of containerDGList) {
        const params = {
          cntrSzCd: cntrSzCd,
          cntrTypCd: cntrTypCd,
          flag: 'maxWt',
          imdunNo: dg.imdunNo,
          imdgSeq: dg.imdgSeq,
          dgMatlCd: dg.dgMatlCd
        }

        const grsWt = Number(dg.grsWt)
        const netWt = Number(dg.netWt)
        const dgRsdCd = dg.dgRsdCd

        let netCnt = []
        let pkgCnt = []

        await trans.getDgMaxCheck(params).then(res => {
          const result = res.data
          if (result !== undefined) {
            if (result.sumGrsWt !== 'N' && grsWt > Number(result.sumGrsWt)) {
              this.$ekmtcCommon.alertDefault('Please check again Gross weight. It should be modified as one container information.')
              return false
            }

            if (dgRsdCd !== '01' && result.maxNetWt !== 'N' && netWt > Number(result.maxNetWt)) {
              if (!confirm('Please input cargo residue weight only in Gross and Net weight.\nTare weight shall be excluded from Gross and Net weight in case ISO Tank.')) {
                return false
              }
            }

            const inPkgQty = Number(dg.isdDgPkgQty)
            const outPkgQty = Number(dg.dgPkgQty)

            if (result.maxOutWt !== 'N' && netWt > (Number(result.maxOutWt) * outPkgQty)) {
              netCnt.push(dg.dgSeq)
            }

            const pkg = (inPkgQty !== 0) ? inPkgQty : outPkgQty
            if (dgRsdCd === '02' && result.maxInWt !== 'N' && (netWt / pkg) > Number(result.maxInWt)) {
              pkgCnt.push(dg.dgSeq)
            }
          }
        })

        if (
            (this.$ekmtcCommon.isNotEmpty(netCnt) && netCnt.length > 0) ||
            (this.$ekmtcCommon.isNotEmpty(pkgCnt) && pkgCnt.length > 0)
          ) {
            let errMsg = ''
            if (netCnt.length > 0) {
              if (netCnt.length === 1) {
                errMsg += 'Please check again package type, quantity corresponding cargo weight.\n'
              } else {
                errMsg += '[Cargo Seq No. ' + netCnt.join(', ') + ']\nPlease check again package type, quantity corresponding cargo weight.\n'
              }
            }

            if (errMsg !== '') errMsg += '\n'

            if (pkgCnt.length > 0) {
              if (pkgCnt.length === 1) {
                errMsg += 'Please input cargo residue weight only in Net weight.\nCurrent weight information is too much considering cargo residue booking.\n'
              } else {
                errMsg += '[Cargo Seq No. ' + pkgCnt.join(', ') + ']\nPlease input cargo residue weight only in Net weight.\nCurrent weight information is too much considering cargo residue booking.\n'
              }
            }

            if (!confirm(errMsg)) {
              return false
            }
          }
      }

      return true
    },
    unnoEnter (e, idx) {
      if (this.unnoDisabledYn === 'Y') { // unno 수정 불가할 경우
        return
      }

      if (e.keyCode === 13) {
        this.getUnnoInfo(idx)
      }
    },
    addContainerDG () {
      if (this.disabledYn === 'Y') {
        this.$ekmtcCommon.alertDefault(this.$t('js.booking.035'))
        return
      }

      const containerDGList = this.dgInfo.containerDGList
      const len = containerDGList.length + 1
      const obj = {
          dgSeq: len,
          imdunNo: '',
          imdgSeq: '',
          imoClasCd: '',
          sbRiskVal1: '',
          sbRiskVal2: '',
          sbRiskVal3: '',
          flsTmpr: '',
          tmprUnitCd: 'C',
          txtPackage: '',
          grsWt: '',
          netWt: '',
          dgPkgGrpCd: '',
          emrTelNo: '',
          emrTelCtrNo: '',
          tchlNm: '',
          tchlNmMdtyYn: '',
          dgPkgQty: '',
          dgMatlCd: '',
          isdDgPkgQty: '',
          dgCatCd: '',
          mpltCd: '',
          emsFeCd: '',
          emsSeCd: '',
          /* lqYn: '', */
          imdgGrpCd: '',
          imdgGrpYn: '',
          dgRsdCd: '',
          saptTmprUnitCd: '', /* SAPT 단위 */
          sp386Yn: '',
          saptTmpr: '',
          casNoList: [
            {
                casNo: '',
                rto: ''
              }
          ],
          casNoObjList: [
            { casNo: '' }
          ],
          addInfoMsg: '',
          addInfoMsgList: [],
          repsCont: ''
        }

        containerDGList.push(obj)
    },
    removeContainerDG (idx) {
      if (this.disabledYn === 'Y') {
        this.$ekmtcCommon.alertDefault(this.$t('js.booking.002'))
        return
      }

      const containerDGList = this.dgInfo.containerDGList || []

      if (containerDGList.length > 0) {
        containerDGList.splice(idx, 1)
        const len = containerDGList.length

        if (len === 0) {
          this.addContainerDG()
        }
      }
    },
    getUnnoInfo (idx) {
      if (this.unnoDisabledYn === 'Y') { // unno 수정 불가할 경우
        return
      }

      const dgInfo = this.dgInfo.containerDGList[idx]
      if (dgInfo !== undefined && dgInfo !== null) {
        this.popParams = {
          unno: dgInfo.imdunNo,
          cntrTypCd: this.cntrTypCd,
          selectIdx: idx
        }

        this.openPopup('unno-pop')
      }
    },
    setUnnoInfo (obj) {
      console.log('@@@@ enter setUnnoInfo: ', obj)
      const TH = this
      const idx = obj.selectIdx
      const dgInfo = this.dgInfo.containerDGList[idx]
      const cntrSeq = this.cntrSeq

      dgInfo.imdunNo = obj.imdunNo
      dgInfo.imoClasCd = obj.imoClasCd
      dgInfo.imdgSeq = obj.imdgSeq
      dgInfo.dgPkgGrpCd = obj.pakgGrpCd
      dgInfo.dsc = obj.dsc
      dgInfo.emsFeCd = obj.emsFeCd
      dgInfo.emsSeCd = obj.emsSeCd
      dgInfo.mpltCd = obj.mpltCd
      dgInfo.tchlNm = obj.tchlNm
      dgInfo.sbRiskVal1 = obj.sbRiskVal1
      dgInfo.sbRiskVal2 = obj.sbRiskVal2
      dgInfo.sbRiskVal3 = obj.sbRiskVal3
      dgInfo.tchlNmMdtyYn = obj.tchlNmMdtyYn
      dgInfo.flsTmpr = ''

      const elImdunNo = document.querySelector('#imdunNo_' + cntrSeq + '_' + idx)
      const elDgPkgGrp = document.querySelector('#dgPkgGrpCd_' + cntrSeq + '_' + idx)
      this.$ekmtcCommon.hideErrorTooltip(elImdunNo)
      this.$ekmtcCommon.hideErrorTooltip(elDgPkgGrp)

      if (obj.tchlNmMdtyYn === 'Y' || obj.mpltCd === 'Y') {
        document.querySelector('#emsFeCd_' + cntrSeq + '_' + idx).disabled = false
        document.querySelector('#emsSeCd_' + cntrSeq + '_' + idx).disabled = false
      } else {
        dgInfo.tchlNm = ''
        document.querySelector('#emsFeCd_' + cntrSeq + '_' + idx).disabled = true
        document.querySelector('#emsSeCd_' + cntrSeq + '_' + idx).disabled = true
      }

      if (obj.imdgGrpCd === 'N') {
        if (obj.tchlNmMdtyYn === 'Y') {
          document.querySelector('#imdgGrpCd_' + cntrSeq + '_' + idx).disabled = false
          dgInfo.imdgGrpYn = 'Y'
        } else {
          document.querySelector('#imdgGrpCd_' + cntrSeq + '_' + idx).disabled = true
          dgInfo.imdgGrpYn = 'N'
        }
        dgInfo.imdgGrpCd = ''
      } else {
        dgInfo.imdgGrpCd = obj.imdgGrpCd
        document.querySelector('#imdgGrpCd_' + cntrSeq + '_' + idx).disabled = true
        dgInfo.imdgGrpYn = 'N'
      }

      if (obj.sp386Yn === 'Y') {
        document.querySelector('#saptTmpr_' + cntrSeq + '_' + idx).disabled = false
        document.querySelector('#saptTmprUnitCd_' + cntrSeq + '_' + idx).disabled = false
        dgInfo.sp386Yn = 'Y'
      } else {
        document.querySelector('#saptTmpr_' + cntrSeq + '_' + idx).disabled = true
        document.querySelector('#saptTmprUnitCd_' + cntrSeq + '_' + idx).disabled = true
        dgInfo.sp386Yn = ''
      }

      /* dgInfo.lqYn = 'N'
      if (this.cntrTypCd === 'TK') {
        document.querySelector('#lqYn_' + cntrSeq + '_' + idx).disabled = true
      } else if (obj.lmtVal === '0') {
        document.querySelector('#lqYn_' + cntrSeq + '_' + idx).disabled = true
      } else {
        document.querySelector('#lqYn_' + cntrSeq + '_' + idx).disabled = false
      } */

      dgInfo.outerPkgList = obj.dgOuterList
      dgInfo.repsCont = ''

      const question = obj.addInfoMsg
      if (question !== undefined && question !== null) {
        dgInfo.addInfoMsg = question.reqCont
        const seq = question.reqSeq

        if (this.$ekmtcCommon.isNotEmpty(seq)) {
          unno.getDgAddInfoList(seq).then((res) => {
            TH.$set(dgInfo, 'addInfoMsgList', res.data)
          })
          // commit
          console.log('@@@@ setUnnoInfo1')
        } else {
          // commit
          console.log('@@@@ setUnnoInfo2')
          this.handleChange()
        }
      } else {
        // commit
        console.log('@@@@ setUnnoInfo3')
      }
    },
    async changeDgOuter (vo, idx) {
      const outer = vo.dgMatlCd
      this.checkValidationDG('dgMatlCd', idx)
      this.$delete(vo, 'innerPkgList')
      if (this.$ekmtcCommon.isNotEmpty(outer)) {
        const params = {
          cntrTypeCd: this.cntrTypCd,
          imdgSeq: vo.imdgSeq,
          imdunNo: vo.imdunNo,
          outer: outer
        }

        const def = {
          cd: '',
          cdNm: ''
        }

        await unno.getDgInnerList(params).then((data) => {
          const newList = []
          const innerPkgList = data.data

          innerPkgList.forEach(c => {
            newList.push({ ...def, ...c })
          })

          if (innerPkgList.length === 0) {
            vo.dgCatCd = ''
          } else if (innerPkgList.length > 1) {
            vo.isdDgPkgQty = '0'
            vo.dgCatCd = ''
          } else if (innerPkgList.length === 1) {
            vo.dgCatCd = innerPkgList[0].cd
          }

          if (newList.length === 0) {
            newList.push({ ...def, ...{ cdNm: '-' } })
          }

          this.$set(vo, 'innerPkgList', newList)
        })
      }
    },
    addCasNo (idx) {
      const obj = {
        casNo: '',
        rto: ''
      }

      const dg = this.dgInfo.containerDGList[idx]
      dg.casNoList.push(obj)

      // commit
      this.handleChange()
      this.forceUpdate++
    },
    removeCasNo (idx, i) {
      const dg = this.dgInfo.containerDGList[idx]
      dg.casNoList.splice(i, 1)
      // commit
      this.handleChange()
      this.forceUpdate++
    },
    canAddDg () {
      if (this.cntrTypCd === 'TK' && this.cgoTypCd === '01') {
        return false
      } else {
        return true
      }
    },
    openPopup (compNm) {
      this.customComponent = compNm
      this.$ekmtcCommon.layerOpen('#dg_area_' + this.cntrSeq + ' > .popup_dim_normal')
    },
    closePoup () {
      this.customComponent = null
      this.$ekmtcCommon.layerClose('#dg_area_' + this.cntrSeq + ' > .popup_dim_normal')
    },
    async openUploadPopup () {
      if (!this.$ekmtcCommon.isEmpty(this.dgInfo.reqRno) && !this.$ekmtcCommon.isEmpty(this.detailBkgNo)) {
        const srContainerFileInfo = await srInquiry.getSrContainerFileInfo(this.dgInfo.reqRno, this.detailBkgNo, 'D')

        this.dgInfo.uploadFileList = [...[], ...srContainerFileInfo.data.uploadFileList]
      }

      this.dgInfo.uploadFileList.map(obj => {
        obj.size = obj.fileSize
        //fileId가 현재 DB는 INT형인데 fileDownload를 위해서 쿼리 스트링 형식으로 바꿔줘야함
        //obj.fileIdOrg = obj.fileId
        obj.fileId = 'http://trans.ekmtc:9000/trans/trans/file-download?reqRno=' + obj.reqRno + '&atchFileSeq=' + obj.atchFileSeq + '&type=DG_OOG_CERTI_BK'
        obj.fileSeq = obj.atchFileSeq
      })
      this.uploadParam = [...[], ...this.dgInfo.uploadFileList]
      this.uploadComponent = 'doc-upload-pop'
      this.$ekmtcCommon.layerOpen('#dg_area_' + this.cntrSeq + ' > .popup_dim_upload')
    },
    async uploadCallbackFunc (fileList) {
      if (!this.$ekmtcCommon.isEmpty(this.detailBkgNo) && !this.$ekmtcCommon.isEmpty(this.cntrSeq)) {
        const uploadFileList = this.$_.cloneDeep(fileList)

        uploadFileList.forEach(element => {
          if (element.newFlag === undefined || element.newFlag === '') {
            element.fileId = ''
          }
        })

        const dgOogParam = {
          reqRno: this.dgInfo.reqRno,
          count: this.cntrSeq,
          type: 'D',
          dgUploadFlag: 'EDIT',
          bkgNo: this.detailBkgNo,
          uploadFileInfos: uploadFileList
        }

        const resultObject = await DgOogCerti.bookingSrPopDgOogUpload(dgOogParam)

        if (resultObject.data.resultCode === 'SUCC') {
          fileList.forEach(element => {
            element.fileId = ''
            delete element.newFlag
            delete element.new
          })
        }
      }

      this.dgInfo.uploadFileList = [...[], ...fileList]

      this.$ekmtcCommon.layerClose('#dg_area_' + this.cntrSeq + ' > .popup_dim_upload')

      this.handleChange()
    },
    isFileUpload () {
      if (this.dgInfo.uploadFileList !== undefined && this.dgInfo.uploadFileList.length > 0) {
        const cntrSeq = this.cntrSeq
        const frm = document.querySelector('#srForm')
        const id = 'srDgFileBox_' + cntrSeq
        const selector = frm.querySelector('#' + id)
        this.$ekmtcCommon.hideErrorTooltip(selector)
        return true
      }
      return false
    },
    closeUploadPopup () {
      this.$ekmtcCommon.layerClose('#dg_area_' + this.cntrSeq + ' > .popup_dim_upload')
    }
  }
}
</script>

<style lang="scss" scoped>
.tbl_col th {
  text-align: center;
}
.desc_list ul li {
  text-align: left;
}

.cas_area:not(:first-child) {margin-top: 5px}
</style>
